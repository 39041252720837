var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "information"
  }, [_c('div', {
    staticClass: "information__box"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.title))]), _vm._l(_vm.dataToShow, function (data, key) {
    return _c('div', {
      key: key,
      staticClass: "information__row"
    }, [_c('div', {
      staticClass: "label"
    }, [_vm._v(" " + _vm._s(_vm.labels[key]))]), _c('div', {
      staticClass: "text"
    }, [_vm._v(_vm._s(_vm.type === 'capitalisation' ? "".concat(_vm.setCurrencyFormat(data.value), " ").concat(data.currency) : data) + " ")])]);
  }), _c('expand-collapse', {
    attrs: {
      "afterExpand": _vm.afterAdditionalInformationExpand,
      "height": 550
    }
  }, [_vm.showMore ? _c('div', {
    staticClass: "additional-information"
  }, [_vm._l(_vm.additionalDataToShow, function (data, key) {
    return [data && data !== '' ? _c('div', {
      key: key,
      staticClass: "information__row"
    }, [_c('div', {
      staticClass: "label"
    }, [_vm._v(" " + _vm._s(_vm.labels[key]))]), _c('div', {
      staticClass: "text"
    }, [_vm._v(" " + _vm._s(_vm.type === 'capitalisation' ? "".concat(_vm.setCurrencyFormat(data.value), " ").concat(data.currency) : data) + " ")])]) : _vm._e()];
  })], 2) : _vm._e()]), _c('div', {
    staticClass: "show_more",
    on: {
      "click": function click($event) {
        _vm.showMore = !_vm.showMore;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.showMore ? 'SHOW LESS' : 'SHOW MORE') + " "), _c('span', {
    class: 'arrows ' + (_vm.showMore ? 'arrows--up' : 'arrows--down')
  }, [_vm._v(" >>>")])])], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }