






























import Vue from 'vue'
import Component from 'vue-class-component'
import SygniCard from "@/components/layout/SygniCard.vue";
import {Prop, Watch} from "vue-property-decorator";
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import {FundData} from "@/modules/genprox/models/User";
import moment from 'moment';

@Component({
  components: {ExpandCollapse, SygniCard},
})
export default class FundDataCard extends Vue {
  @Prop() data: FundData;
  @Prop() title: string;
  @Prop() type: string;

  emptyPlaceholder: string = '---';

  cardData: FundData = {
    programme: null,
    commencementDate: null,
    investmentAgreementDate: null,
    endOfInvestmentPeriod: null,
    fundClosingDate: null,
    capitalisation: null,
    fundManager: null,
    publicInvestor: null,
    privateInvestor: null,
    investmentBudget: null,
    operationalBudget: null,
  }
  showMore: boolean = false;
  labels: object = {
    programme: '',
    commencementDate: 'Commencement date',
    investmentAgreementDate: 'Investment agreement date',
    endOfInvestmentPeriod: 'End of investment period',
    fundClosingDate: 'Fund closing date',
    capitalisation: 'Capitalisation',
    fundManager: 'Fund manager',
    publicInvestor: 'Public investor',
    privateInvestor: 'Private investor',
    investmentBudget: 'Investment budget',
    operationalBudget: 'Operational budget'
  };

  get dataToShow(): object {
    let data: object = {};

    if (this.type === 'programme') {
      const {
        programme,
        commencementDate,
        investmentAgreementDate,
      } = this.cardData;

      data = {
        programme: programme == null ? this.emptyPlaceholder : programme,
        commencementDate: commencementDate == null ? this.emptyPlaceholder : this.dateFormat(commencementDate),
        investmentAgreementDate: investmentAgreementDate == null ? this.emptyPlaceholder : this.dateFormat(investmentAgreementDate),
      };

    } else if (this.type === 'capitalisation') {
      const {
        capitalisation,
        fundManager,
        publicInvestor,
      } = this.cardData;

      data = {
        capitalisation: {
          value: capitalisation == null ? this.emptyPlaceholder : capitalisation.value,
          currency: capitalisation == null ? '' : capitalisation.currency,
        },
        fundManager: {
          value: fundManager == null ? this.emptyPlaceholder : fundManager.value,
          currency: fundManager == null ? '' : fundManager.currency,
        },
        publicInvestor: {
          value: publicInvestor == null ? this.emptyPlaceholder : publicInvestor.value,
          currency: publicInvestor == null ? '' : publicInvestor.currency,
        }
      };
    }

    return data ? data : [];
  }

  get additionalDataToShow(): object {
    let data: object = {};

    if (this.type === 'programme') {
      const {
        endOfInvestmentPeriod,
        fundClosingDate,
      } = this.cardData;

      data = {
        endOfInvestmentPeriod: endOfInvestmentPeriod == null ? this.emptyPlaceholder : this.dateFormat(endOfInvestmentPeriod),
        fundClosingDate: fundClosingDate == null ? this.emptyPlaceholder : this.dateFormat(this.data?.fundClosingDate),
      };
    } else if (this.type === 'capitalisation') {
      const {
        privateInvestor,
        investmentBudget,
        operationalBudget
      } = this.cardData;

      data = {
        privateInvestor: {
          value: privateInvestor == null ? this.emptyPlaceholder : privateInvestor.value,
          currency: privateInvestor == null ? '' : privateInvestor.currency,
        },
        investmentBudget: {
          value: investmentBudget == null ? this.emptyPlaceholder : investmentBudget.value,
          currency: investmentBudget == null ? '' : investmentBudget.currency,
        },
        operationalBudget: {
          value: operationalBudget == null ? this.emptyPlaceholder : operationalBudget.value,
          currency: operationalBudget == null ? '' : operationalBudget.currency,
        }
      };
    }

    return data ? data : [];
  }

  formatK(n: number) {
    if (n < 1e3) return n;
    if (n >= 1e3) {
      let number: string | number = +(n / 1e3).toFixed(0);
      number = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
      return number + " K"
    }
  }

  setCurrencyFormat(value: number | string) {
    if (typeof value == 'string') {
      return this.emptyPlaceholder;
    } else {
      return this.formatK(value);
    }
  }

  dateFormat(date: any) {
    if (date === undefined) return '';
    return moment(date).format("YYYY-MM-DD")
  }

  @Watch('data') onDateChange() {
    this.cardData = this.data;
  }

  mounted() {
  }

  afterAdditionalInformationExpand(el: HTMLDivElement): any {
    el.style.overflow = 'visible';
  }
}
