

























import Vue from "vue";
import Component from "vue-class-component";
import SygniCard from "@/components/layout/SygniCard.vue";
import {Prop} from "vue-property-decorator";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";

@Component({
  components: {SygniCard, SygniRoundedButton},
})
export default class FundProducts extends Vue {
  @Prop() products: any;

  mounted() {
  }
}
