var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fund-products"
  }, [_vm._m(0), _c('sygni-card', {
    staticClass: "fund-products__content"
  }, [!_vm.products.length ? _c('div', {
    staticClass: "fund-products__empty"
  }, [_c('div', {
    staticClass: "fund-products__empty-icon"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/cart.svg"),
      "alt": "Avatar"
    }
  })]), _c('p', {
    staticClass: "fund-products__empty-title"
  }, [_vm._v("You don't have any Products")]), _c('sygni-rounded-button', {
    staticClass: "filled gn-primary",
    attrs: {
      "hoverable": true,
      "plus-icon": true
    }
  }, [_vm._v(" Add new product ")])], 1) : _vm._e()])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fund-products__header"
  }, [_c('p', {
    staticClass: "fund-products__title"
  }, [_vm._v("Product list")])]);
}]

export { render, staticRenderFns }